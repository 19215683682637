<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { _ } from "lodash";
import { FwbFileInput } from "flowbite-vue";
import { vMaska } from "maska/vue";
import { TrashIcon } from "@heroicons/vue/24/solid/index.js";
import VButton from "@/Components/BaseComponents/VButton.vue";
import { useDocumentStore } from "@/Store/document.js";
import { trans } from "../../vendor/inertia-scaffold/trans.js";

const page = usePage();
const { formatNumber } = useDocumentStore();
const errors = computed(() => page.props.errors);
const slots = defineSlots();
const props = defineProps({
    displayOnlyValue: {
        type: String,
        default: null,
        required: false,
    },
    type: {
        type: String,
        default: "text",
        required: false,
    },
    values: {
        type: Object,
        default: null,
        required: false,
    },
    label: {
        type: String,
        default: null,
        required: false,
    },
    name: {
        type: String,
        default: null,
        required: false,
    },
    modelValue: {
        default: null,
        required: false,
    },
    validation: {
        type: Object,
        default: null,
        required: false,
    },
    example: {
        type: String,
        default: null,
        required: false,
    },
    class: {
        type: String,
        default: "",
        required: false,
    },
    placeholder: {
        type: String,
        default: null,
        required: false,
    },
    inputClasses: {
        type: String,
        default: "",
        required: false,
    },
    labelClasses: {
        type: String,
        default: "",
        required: false,
    },
    exampleClasses: {
        type: String,
        default: "",
        required: false,
    },
    errorClasses: {
        type: String,
        default: "",
        required: false,
    },
    suffixClasses: {
        type: String,
        default: "",
        required: false,
    },
    radioBtnClasses: {
        type: String,
        default: "",
        required: false,
    },
    hasPrefixSelect: {
        type: Boolean,
        default: false,
        required: false,
    },
    inputRef: {
        default: null,
        required: false,
    },
    showErrors: {
        type: Boolean,
        default: true,
        required: false,
    },
    multiple: {
        type: Boolean,
        default: false,
        required: false,
    },
    mask: {
        type: String,
        default: null,
        required: false,
    },
    vatButton: {
        type: Boolean,
        default: false,
        required: false,
    },
    vatValue: {
        type: Boolean,
        default: false,
        required: false,
    },
});

onMounted(() => {
    if (props.vatButton) {
        toggleVatOption(true);
    }
});

const generatedClasses = computed(() => {
    if (props.type.toLowerCase() === "select2") {
        return "select2";
    }
    let baseClasses = "items-baseline lg:flex lg:flex-row ";
    if (props.displayOnlyValue != null) {
        baseClasses += "text-sm ";
    }
    return baseClasses + props.class;
});
const generatedPrefixClasses = computed(() => {
    let classes = "prefix";
    if (props.hasPrefixSelect) {
        classes += " p-0";
    } else {
        classes += "  px-3";
    }
    return classes;
});
const emits = defineEmits(["update:modelValue"]);
const hasPrefix = computed(() => slots.prefix !== undefined);
const hasSuffix = computed(() => slots.suffix !== undefined);
const hasContent = computed(() => slots.content !== undefined);
const generatedInputClasses = computed(() => {
    let classes = _.get(props, "inputClasses", "") + " ";
    if (props.type.toLowerCase() === "radio") {
        return classes;
    }
    if (props.type.toLowerCase() !== "checkbox") {
        classes += "w-full ";
    }
    if (!hasPrefix.value && !hasSuffix.value) {
        return classes + "rounded";
    } else if (hasPrefix.value) {
        return classes + "rounded-r";
    } else if (hasSuffix.value) {
        return classes + "rounded-l";
    }
});
const getSelect2Options = computed(() => {
    if (props.values === null) return [];
    return _.toArray(
        props.values.map((value) => {
            return {
                code: value.key ?? [],
                label: value.name,
            };
        }),
    );
});
let modelValueCopy = ref(props.modelValue);
// watch test for changes
watch(modelValueCopy, (newValue, oldValue) => {
    emits("update:modelValue", newValue);
});
function optionIsSelected(key, object) {}

function handleFileUpload(event) {
    const files = Array.from(event.target.files);
    //add files to modelValueCopy
    const acceptedFileTypes = ["image/", "application/pdf"];
    _(files).forEach((file) => {
        if (acceptedFileTypes.some((type) => file.type.startsWith(type))) {
            modelValueCopy.value.push({
                file: file,
                tempUrl: URL.createObjectURL(file),
            });
        }
    });

    event.target.value = "";
}

const alignment = computed(() => {
    var returnvalue = "";
    if (props.displayOnlyValue !== null) {
        if (props.inputClasses.includes("text-right")) {
            returnvalue = "justify-end";
        }
    }
    return returnvalue;
});

let vatOption = ref("vat_excluded");

const toggleVatOption = (initiate = false) => {
    if (initiate === true) {
        props.vatValue
            ? (vatOption.value = "vat_excluded")
            : (vatOption.value = "vat_included");
    } else {
        vatOption.value =
            vatOption.value === "vat_included"
                ? "vat_excluded"
                : "vat_included";
    }

    emits("vatChanges", vatOption.value);
};
</script>

<template>
    <div
        v-if="displayOnlyValue != null"
        v-bind="$attrs"
        :class="generatedClasses + ' ' + alignment"
    >
        <template
            v-if="displayOnlyValue == 'text' || displayOnlyValue == 'number'"
        >
            {{ props.modelValue }}
        </template>
        <template v-else-if="displayOnlyValue == 'price'">
            {{ formatNumber(props.modelValue) }}
        </template>
        <template v-else-if="displayOnlyValue == 'percentage'">
            {{ props.modelValue }}%
        </template>
        <template v-else-if="displayOnlyValue == 'time'">
            {{ props.modelValue }}
        </template>
    </div>
    <div v-else :class="'v-input ' + generatedClasses">
        <label
            v-if="props.label != null && type.toLowerCase() !== 'checkbox'"
            :class="labelClasses"
        >
            <span v-if="vatButton" class="vat-label" @click="toggleVatOption">
                {{ trans("general." + vatOption) }}
            </span>
            {{ props.label }}
            <span
                v-if="validation != null && validation.required"
                class="text-danger"
            >
                *
            </span>
        </label>
        <div :class="'flex ' + inputClasses">
            <span v-if="hasPrefix" :class="generatedPrefixClasses">
                <slot name="prefix"></slot>
            </span>
            <textarea
                v-bind="$attrs"
                :id="props.name"
                v-if="type.toLowerCase() === 'textarea'"
                :ref="props.inputRef"
                :name="props.name"
                :value="modelValue"
                :class="generatedInputClasses"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            </textarea>
            <label
                v-else-if="type.toLowerCase() === 'checkbox'"
                :class="labelClasses"
            >
                <input
                    v-bind="$attrs"
                    :id="props.name"
                    :type="type"
                    :ref="props.inputRef"
                    :name="props.name"
                    :checked="modelValue"
                    :class="generatedInputClasses"
                    @input="$emit('update:modelValue', $event.target.checked)"
                />
                <span v-if="props.label" class="pl-2">{{ props.label }}</span>
                <span
                    v-if="validation != null && validation.required"
                    class="text-danger"
                >
                    *
                </span>
            </label>
            <!-- TODO: uit te zoeken of dit ergens problemen geeft v-bind="$attrs"-->
            <select
                :id="props.name"
                v-else-if="type.toLowerCase() === 'select'"
                :ref="props.inputRef"
                :name="props.name"
                :value="modelValue ?? ''"
                :class="generatedInputClasses"
                @input="$emit('update:modelValue', $event.target.value)"
            >
                <template v-for="value in values">
                    <option
                        v-if="typeof value.name === 'string'"
                        :value="value.key ?? ''"
                        :selected="optionIsSelected(value.key, values)"
                    >
                        {{ value.name }}
                    </option>
                    <optgroup :label="value.key" v-else>
                        <option
                            v-for="v in value.values"
                            :value="v.key"
                            :selected="optionIsSelected(v.key, value.values)"
                        >
                            {{ v.name }}
                        </option>
                    </optgroup>
                </template>
            </select>
            <v-select
                v-bind="$attrs"
                v-else-if="type.toLowerCase() === 'select2'"
                :reduce="(option) => option.code"
                v-model="modelValueCopy"
                :class="generatedInputClasses"
                :options="getSelect2Options"
                label="label"
            />
            <label
                v-else-if="type.toLowerCase() === 'radio'"
                v-for="(value, key) in values"
                :class="labelClasses"
            >
                <div :class="['option', radioBtnClasses]">
                    <input
                        type="radio"
                        :ref="props.inputRef"
                        :name="props.name"
                        v-model="modelValueCopy"
                        :value="value.key"
                        :class="generatedInputClasses"
                        @input="$emit('update:modelValue', $event.target.value)"
                    />
                    <template v-if="!value.description">
                        {{ value.name }}
                    </template>
                    <template v-else>
                        <h4>{{ value.name }}</h4>
                    </template>
                </div>
                <template v-if="value.description">
                    <p>{{ value.description }}</p>
                </template>
                <slot
                    name="content"
                    v-if="hasContent"
                    v-bind:value="value"
                ></slot>
            </label>
            <fwb-file-input
                v-bind="$attrs"
                dropzone
                v-else-if="type.toLowerCase() == 'dropzone'"
                multiple
                :id="props.name"
                :placeholder="props.placeholder"
                :ref="props.inputRef"
                :name="props.name"
                @change="handleFileUpload"
                :class="generatedInputClasses + ' dropzone'"
            />
            <input
                v-bind="$attrs"
                v-maska="props.mask"
                :multiple="props.multiple"
                :id="props.name"
                v-else
                :placeholder="props.placeholder"
                :ref="props.inputRef"
                :name="props.name"
                :type="type"
                :value="modelValue"
                :class="generatedInputClasses"
                :required="validation != null && validation.required"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <span
                v-if="hasSuffix"
                :class="
                    'suffix inline-flex items-center px-3 text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r ' +
                    suffixClasses
                "
            >
                <slot name="suffix"></slot>
            </span>
        </div>
        <template
            v-if="type.toLowerCase() == 'dropzone' && modelValueCopy.length"
        >
            <ul class="col-start-2 list-disc list-inside list-dropzone">
                <li
                    v-for="(attachment, index) in modelValueCopy"
                    :key="'attachment' + index"
                >
                    <a
                        :href="attachment.tempUrl"
                        target="_blank"
                        class="transition hover:underline"
                    >
                        {{ attachment.file.name }}
                    </a>
                    <v-button
                        class="text-gray-400 hover:text-white"
                        @click="modelValueCopy.splice(index, 1)"
                        kind="icon"
                    >
                        <trash-icon class="!custom-stroke w-5 h-5"
                    /></v-button>
                </li>
            </ul>
        </template>
        <span
            v-if="errors[props.name] && props.showErrors"
            :class="'text-danger ' + errorClasses"
            >{{ errors[props.name] }}</span
        >
        <span v-if="props.example" :class="'example ' + exampleClasses">{{
            props.example
        }}</span>
    </div>
</template>

<style scoped lang="scss">
.prefix {
    @apply inline-flex items-center text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l font-normal;
    flex-basis: 0;
    max-height: 38px;

    select {
        @apply bg-gray-200;
    }
}
.no-arrow select {
    @apply px-2;
    background-image: none;
    &.rounded-r {
    }
}

.vat-label {
    @apply inline-block uppercase cursor-pointer;
    background-color: #4d9cd7;
    border-radius: 3px;
    font-size: 9px;
    line-height: 10px;
    margin: 0 5px;
    padding: 5px !important;
    color: #fff !important;
    font-weight: bold !important;
}
</style>
